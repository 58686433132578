const COMMAND = 'copy';

const copyToClipboard = str => {
  return new Promise((resolve, reject) => {
    if (!document.queryCommandSupported(COMMAND)) {
      reject(Error('Browser unable to use document.execCommand'));
    }

    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand(COMMAND);
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    resolve(str);
  });
};

export default {
  copy: copyToClipboard
};
