<template>
  <div
    class="app-copy-text tw-border-border-color tw-bg-jb-grey-50 tw-text-bg-light-color tw-px-space-3 tw-py-space-3 md:tw-max-w-sm lg:tw-max-w-xs tw-flex tw-items-center tw-justify-between tw-max-w-sm tw-border tw-rounded"
    @click.prevent="handleClick">
    <slot name="link" :link="link">
      <app-link
        class="link !tw-text-jb-grey-700 tw-truncate tw-max-w-max"
        :size="responsiveText"
        underline="always"
        :class="isCopied ?'tw-bg-jb-grey-100' : 'tw-text-grey-white-ter'">
        {{ link }}
      </app-link>
    </slot>
    <slot name="actionText" :action-text="actionText">
      <app-text
        class="sm:hover:tw-underline sm:active:tw-underline tw-text-jb-indigo copy tw-text-sm tw-cursor-pointer"
        weight="medium"
        :size="responsiveText">
        {{ isCopied ? 'Copied' : actionText }}
      </app-text>
    </slot>
  </div>
</template>

<script>
import CopyToClipboardService from '@/services/copy-to-clipboard-service';
import AppText from '@/components/AppText';
import AppLink from '@/components/AppLink';
import Breakpoints from '@/mixins/Breakpoints';

export default {
  name: 'AppCopyText',
  components: { AppText, AppLink },
  mixins: [Breakpoints([450, 768, 992])],
  props: {
    link: {
      type: String,
      required: true
    },
    actionText: {
      type: String,
      default: 'Copy'
    }
  },
  data() {
    return {
      isCopied: false
    };
  },
  computed: {
    responsiveText() {
      return this.windowBreakpoint < 450 ? 'xs' : 'sm';
    }
  },
  methods: {
    handleClick() {
      if (this.isCopied) return;

      this.isCopied = true;

      setTimeout(() => { this.isCopied = false; }, 1000);

      CopyToClipboardService.copy(this.link)
        .then(val => {
          this.$emit('link-emit', val);
          this.$message({
            showClose: true,
            message: 'Copied to clipboard'
          });
        })
        .catch(e => {
          this.$message({
            message: 'There was an error copying the link.',
            type: 'error'
          });
        });
    }
  }
};

</script>

<style lang="scss">
.app-copy-text {
  .link {
    width: calc(100% - 40px);
  }
}
</style>
