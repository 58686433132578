
<template>
  <div class="organization-conversion-apis">
    <header class="domains-header tw-mt-layout-1 tw-flex tw-items-center tw-justify-between">
      <app-heading level="h3" size="h5" class="sm:tw-text-h4 tw-m-0">
        Conversion APIs
      </app-heading>
    </header>
    <div class="organization-conversion-apis__google tw-p-layout-1 tw-mt-space-3 tw-rounded">
      <app-text slot="reference" type="h4" weight="semi-bold" class="tw-inline-block tw-p-0 tw-mt-0 tw-mb-1 tw-leading-tight tw-text-white">
        Google Offline Conversion API
      </app-text>
      <app-text type="p" size="sm" class="tw-block tw-mb-2 tw-text-white">
        Use the below URL to configure a scheduled offline conversion upload routine in Google Ads.
        <app-link :external="true" :href="docsUrl" weight="semibold" color="white" class="hover:tw-underline tw-cursor-pointer !tw-text-white">
          Learn More
        </app-link>
      </app-text>
      <app-copy-text class="!tw-py-space-2 !tw-max-w-full tw-relative tw-z-1" :link="link" />
    </div>
  </div>
</template>

<script>
import AppHeading from '@/components/AppHeading.vue';
import AppText from '@/components/AppText';
import AppLink from '@/components/AppLink.vue';
import AppCopyText from '@/components/AppCopyText';
import config from '@/helpers/config';

export default {
  name: 'OrganizationConversionApis',
  components: { AppHeading, AppCopyText, AppLink, AppText },
  data() {
    return {
      docsUrl: '/docs/affiliate/tracking/?title=uploading-offline-conversions'
    };
  },
  computed: {
    apiUrl() {
      return config.VUE_APP_PLATFORM_API_URL;
    },
    organizationId() {
      return this.$store.getters.organizationId;
    },
    sessionId() {
      return this.$store.getters.user.session_id;
    },
    isAdmin() {
      return this.$store.getters.userIsAdmin;
    },
    link() {
      return this.isAdmin
        ? 'Client must retrieve this value on their own'
        : `${this.apiUrl}/v2/org/${this.organizationId}/campaigns/reporting/google?api-token=${this.sessionId}&dateRange=2D&conversion_name=Purchase`;
    }
  }
};
</script>

<style lang="scss">
.organization-conversion-apis__google {
  --gradient-start: theme('colors.jb-indigo.darker');
  --gradient-end: theme('colors.jb-indigo.DEFAULT');

  background: linear-gradient(-45deg, var(--gradient-start) 24%, var(--gradient-end) 24%);
}
</style>
